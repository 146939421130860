import { HttpErrorResponse } from '@angular/common/http';
import { VersionInformation } from '@curve-builder/shared/api/curve/generated';
import { CurveDefinition } from '@curve-builder/shared/api/definition/generated';
import { createActionGroup, props } from '@ngrx/store';
import { FieldLabelDto, SymbolInformation } from '@shared/api/data-management/generated';

export const CurveSymbolActions = createActionGroup({
    source: 'Symbols',
    events: {
        'Fetch Curve Fields': props<{ symbol: string; force?: boolean }>(),
        'Fetch Curve Fields Success': props<{ symbol: string; fields: string[] }>(),
        'Fetch Curve Fields Error': props<{ symbol: string; error: HttpErrorResponse }>(),

        'Fetch Curve OnDates': props<{ symbol: string; field?: string; force?: boolean }>(),
        'Fetch Curve OnDates Success': props<{ symbol: string; field?: string; onDates: string[] }>(),
        'Fetch Curve OnDates Error': props<{ symbol: string; field?: string; error: HttpErrorResponse }>(),

        'Fetch Curve Versions': props<{ symbol: string; field?: string; onDate: string; force?: boolean }>(),
        'Fetch Curve Versions Success': props<{ symbol: string; field?: string; onDate: string; versions: VersionInformation[] }>(),
        'Fetch Curve Versions Error': props<{ symbol: string; field?: string; onDate: string; error: HttpErrorResponse }>(),

        'Fetch Curve Definition': props<{ symbol: string; force?: boolean }>(),
        'Fetch Curve Definition Success': props<{ symbol: string; curveDefinition: CurveDefinition }>(),
        'Fetch Curve Definition Error': props<{ symbol: string; error: HttpErrorResponse }>()
    }
});
