import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { FieldLabelDto, SymbolInformation } from '@shared/api/data-management/generated';

export const SymbolActions = createActionGroup({
    source: 'Symbols',
    events: {
        'Fetch Symbol Fields': props<{ symbol: string; force?: boolean }>(),
        'Fetch Symbol Fields Success': props<{ symbol: string; fields: FieldLabelDto[] }>(),
        'Fetch Symbol Fields Error': props<{ symbol: string; error: HttpErrorResponse }>(),

        'Fetch Curve Symbol Fields': props<{ symbol: string; force?: boolean }>(),
        'Fetch Curve Symbol Fields Success': props<{ symbol: string; fields: FieldLabelDto[] }>(),
        'Fetch Curve Symbol Fields Error': props<{ symbol: string; error: HttpErrorResponse }>(),

        'Fetch Symbol Information': props<{ symbol: string; force?: boolean }>(),
        'Fetch Symbol Information Success': props<{ symbol: string; symbolInformation: SymbolInformation }>(),
        'Fetch Symbol Information Error': props<{ symbol: string; error: HttpErrorResponse }>()
    }
});
