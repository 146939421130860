import { HttpErrorResponse } from '@angular/common/http';

export type FetchStateProps = {
    lastFetchStart: Date;
    lastFetchEnd: Date;
    loading: boolean;
    loaded: boolean;
    loadingError: boolean;
    loadingErrorResponse: HttpErrorResponse;
};

/**
 * Fetch state prop generators
 */
export function fetchStartProps(previouslyLoaded: boolean = false): FetchStateProps {
    return {
        loading: true,
        loaded: previouslyLoaded,
        loadingError: false,
        loadingErrorResponse: null,
        lastFetchStart: new Date(),
        lastFetchEnd: null
    };
}

export function fetchSuccessProps(): Omit<FetchStateProps, 'lastFetchStart'> {
    return {
        loading: false,
        loaded: true,
        loadingError: false,
        loadingErrorResponse: null,
        lastFetchEnd: new Date()
    };
}

export function fetchErrorProps(error: HttpErrorResponse): Omit<FetchStateProps, 'lastFetchStart'> {
    return {
        loading: false,
        loaded: false,
        loadingError: true,
        loadingErrorResponse: error,
        lastFetchEnd: new Date()
    };
}
