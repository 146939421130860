import {
    buildCurveOnDatesEntityId,
    buildCurveVersionsEntityId,
    curveDefinitionAdapter,
    curveFieldsAdapter,
    curveOnDatesAdapter,
    curveSymbolFeature,
    curveVersionsAdapter
} from '@app/+shared-state/curve-symbol/curve-symbol.reducer';
import { createSelector } from '@ngrx/store';

export const { selectCurveDefinitions, selectCurveFields, selectCurveOnDates, selectCurveVersions } = curveSymbolFeature;

/**
 * Curve fields selectors
 */
export const {
    selectIds: _selectCurveFieldsIds,
    selectEntities: _selectCurveFieldsEntities,
    selectAll: _selectAllCurveFields,
    selectTotal: _selectTotalCurveFields
} = curveFieldsAdapter.getSelectors();

export const selectCurveFieldsIds = createSelector(selectCurveFields, _selectCurveFieldsIds);
export const selectCurveFieldsEntities = createSelector(selectCurveFields, _selectCurveFieldsEntities);
export const selectAllCurveFields = createSelector(selectCurveFields, _selectAllCurveFields);
export const selectTotalCurveFields = createSelector(selectCurveFields, _selectTotalCurveFields);
export const selectCurveFieldsForSymbol = (symbol: string) => createSelector(selectCurveFieldsEntities, curveFields => curveFields[symbol] || null);

/**
 * Curve on-dates selectors
 */
export const {
    selectIds: _selectCurveOnDatesIds,
    selectEntities: _selectCurveOnDatesEntities,
    selectAll: _selectAllCurveOnDates,
    selectTotal: _selectTotalCurveOnDates
} = curveOnDatesAdapter.getSelectors();

export const selectCurveOnDatesIds = createSelector(selectCurveOnDates, _selectCurveOnDatesIds);
export const selectCurveOnDatesEntities = createSelector(selectCurveOnDates, _selectCurveOnDatesEntities);
export const selectAllCurveOnDates = createSelector(selectCurveOnDates, _selectAllCurveOnDates);
export const selectTotalCurveOnDates = createSelector(selectCurveOnDates, _selectTotalCurveOnDates);
export const selectCurveOnDatesForSymbol = (symbol: string, field: string = 'CURVE') => {
    const id = buildCurveOnDatesEntityId(symbol, field);
    return createSelector(selectCurveOnDatesEntities, curveOnDates => curveOnDates[id] || null);
};

/**
 * Curve versions selectors
 */
export const {
    selectIds: _selectCurveVersionsIds,
    selectEntities: _selectCurveVersionsEntities,
    selectAll: _selectAllCurveVersions,
    selectTotal: _selectTotalCurveVersions
} = curveVersionsAdapter.getSelectors();

export const selectCurveVersionsIds = createSelector(selectCurveVersions, _selectCurveVersionsIds);
export const selectCurveVersionsEntities = createSelector(selectCurveVersions, _selectCurveVersionsEntities);
export const selectAllCurveVersions = createSelector(selectCurveVersions, _selectAllCurveVersions);
export const selectTotalCurveVersions = createSelector(selectCurveVersions, _selectTotalCurveVersions);
export const selectCurveVersionsForSymbol = (symbol: string, field: string = 'CURVE', onDate: string) => {
    const id = buildCurveVersionsEntityId(symbol, field, onDate);
    return createSelector(selectCurveVersionsEntities, curveVersions => curveVersions[id] || null);
};

/**
 * Curve definition selectors
 */
export const {
    selectIds: _selectCurveDefinitionIds,
    selectEntities: _selectCurveDefinitionEntities,
    selectAll: _selectAllCurveDefinitions,
    selectTotal: _selectTotalCurveDefinitions
} = curveDefinitionAdapter.getSelectors();

export const selectCurveDefinitionIds = createSelector(selectCurveDefinitions, _selectCurveDefinitionIds);
export const selectCurveDefinitionEntities = createSelector(selectCurveDefinitions, _selectCurveDefinitionEntities);
export const selectAllCurveDefinitions = createSelector(selectCurveDefinitions, _selectAllCurveDefinitions);
export const selectTotalCurveDefinitions = createSelector(selectCurveDefinitions, _selectTotalCurveDefinitions);
export const selectCurveDefinitionForSymbol = (symbol: string) => {
    return createSelector(selectCurveDefinitionEntities, curveDefinitions => curveDefinitions[symbol] || null);
};
