import { CurveSymbolActions } from '@app/+shared-state/curve-symbol/curve-symbol.actions';
import { VersionInformation } from '@curve-builder/shared/api/curve/generated';
import { CurveDefinition } from '@curve-builder/shared/api/definition/generated';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { fetchErrorProps, fetchStartProps, FetchStateProps, fetchSuccessProps } from '../fetch-state-props';

export const curveSymbolFeatureKey = 'curveSymbol';

export type CurveFields = { id: string; symbol: string; fields: string[] } & FetchStateProps;
export const curveFieldsAdapter: EntityAdapter<CurveFields> = createEntityAdapter({
    selectId: s => s.id
});

export type CurveOnDates = { id: string; symbol: string; field: string; onDates: string[] } & FetchStateProps;
export const buildCurveOnDatesEntityId = (symbol: string, field: string = 'CURVE') => `${symbol}:${field}`;
export const curveOnDatesAdapter: EntityAdapter<CurveOnDates> = createEntityAdapter({
    selectId: s => s.id
});

export type CurveVersions = { id: string; symbol: string; field: string; onDate: string; versions: VersionInformation[] } & FetchStateProps;
export const buildCurveVersionsEntityId = (symbol: string, field: string, onDate: string) => `${symbol}:${field}:${onDate}`;
export const curveVersionsAdapter: EntityAdapter<CurveVersions> = createEntityAdapter({
    selectId: s => s.id
});

export type StoreCurveDefinition = { symbol: string; curveDefinition: CurveDefinition } & FetchStateProps;
export const curveDefinitionAdapter: EntityAdapter<StoreCurveDefinition> = createEntityAdapter({
    selectId: s => s.symbol
});

export type CurveSymbolState = {
    curveFields: EntityState<CurveFields>;
    curveOnDates: EntityState<CurveOnDates>;
    curveVersions: EntityState<CurveVersions>;
    curveDefinitions: EntityState<StoreCurveDefinition>;
};

export const initialState: CurveSymbolState = {
    curveFields: curveFieldsAdapter.getInitialState(),
    curveOnDates: curveOnDatesAdapter.getInitialState(),
    curveVersions: curveVersionsAdapter.getInitialState(),
    curveDefinitions: curveDefinitionAdapter.getInitialState()
};

export const curveSymbolReducer = createReducer<CurveSymbolState>(
    initialState,

    /**
     * Curve fields
     */
    on(CurveSymbolActions.fetchCurveFields, (state, { force, symbol }) => {
        const existing = state.curveFields.entities[symbol];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: CurveFields = {
            id: symbol,
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.curveFields.entities[symbol]
        };
        return {
            ...state,
            curveFields: curveFieldsAdapter.upsertOne(upsertValue, state.curveFields)
        };
    }),
    on(CurveSymbolActions.fetchCurveFieldsSuccess, (state, { symbol, fields }) => {
        const update: Update<CurveFields> = {
            id: symbol,
            changes: {
                ...fetchSuccessProps(),
                fields
            }
        };
        return {
            ...state,
            curveFields: curveFieldsAdapter.updateOne(update, state.curveFields)
        };
    }),
    on(CurveSymbolActions.fetchCurveFieldsError, (state, { symbol, error }) => {
        const update: Update<CurveFields> = {
            id: symbol,
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            curveFields: curveFieldsAdapter.updateOne(update, state.curveFields)
        };
    }),

    /**
     * Curve on-dates
     */
    on(CurveSymbolActions.fetchCurveOnDates, (state, { force, symbol, field }) => {
        field = field || 'CURVE';
        const existing = state.curveOnDates.entities[buildCurveOnDatesEntityId(symbol, field)];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: CurveOnDates = {
            id: buildCurveOnDatesEntityId(symbol, field),
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.curveOnDates.entities[symbol]
        };
        return {
            ...state,
            curveOnDates: curveOnDatesAdapter.upsertOne(upsertValue, state.curveOnDates)
        };
    }),
    on(CurveSymbolActions.fetchCurveOnDatesSuccess, (state, { symbol, field, onDates }) => {
        field = field || 'CURVE';
        const update: Update<CurveOnDates> = {
            id: buildCurveOnDatesEntityId(symbol, field),
            changes: {
                ...fetchSuccessProps(),
                onDates
            }
        };
        return {
            ...state,
            curveOnDates: curveOnDatesAdapter.updateOne(update, state.curveOnDates)
        };
    }),
    on(CurveSymbolActions.fetchCurveOnDatesError, (state, { symbol, field, error }) => {
        field = field || 'CURVE';
        const update: Update<CurveOnDates> = {
            id: buildCurveOnDatesEntityId(symbol, field),
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            curveOnDates: curveOnDatesAdapter.updateOne(update, state.curveOnDates)
        };
    }),

    /**
     * Curve versions
     */
    on(CurveSymbolActions.fetchCurveVersions, (state, { force, symbol, field, onDate }) => {
        field = field || 'CURVE';
        const existing = state.curveVersions.entities[buildCurveVersionsEntityId(symbol, field, onDate)];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: CurveVersions = {
            id: buildCurveVersionsEntityId(symbol, field, onDate),
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.curveVersions.entities[symbol]
        };
        return {
            ...state,
            curveVersions: curveVersionsAdapter.upsertOne(upsertValue, state.curveVersions)
        };
    }),
    on(CurveSymbolActions.fetchCurveVersionsSuccess, (state, { symbol, field, onDate, versions }) => {
        field = field || 'CURVE';
        const update: Update<CurveVersions> = {
            id: buildCurveVersionsEntityId(symbol, field, onDate),
            changes: {
                ...fetchSuccessProps(),
                versions
            }
        };
        return {
            ...state,
            curveVersions: curveVersionsAdapter.updateOne(update, state.curveVersions)
        };
    }),
    on(CurveSymbolActions.fetchCurveVersionsError, (state, { symbol, field, onDate, error }) => {
        field = field || 'CURVE';
        const update: Update<CurveVersions> = {
            id: buildCurveVersionsEntityId(symbol, field, onDate),
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            curveVersions: curveVersionsAdapter.updateOne(update, state.curveVersions)
        };
    }),

    /**
     * Curve definitions
     */
    on(CurveSymbolActions.fetchCurveDefinition, (state, { force, symbol }) => {
        const existing = state.curveDefinitions.entities[symbol];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: StoreCurveDefinition = {
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.curveDefinitions.entities[symbol]
        };
        return {
            ...state,
            curveDefinitions: curveDefinitionAdapter.upsertOne(upsertValue, state.curveDefinitions)
        };
    }),
    on(CurveSymbolActions.fetchCurveDefinitionSuccess, (state, { symbol, curveDefinition }) => {
        const update: Update<StoreCurveDefinition> = {
            id: symbol,
            changes: {
                ...fetchSuccessProps(),
                curveDefinition
            }
        };
        return {
            ...state,
            curveDefinitions: curveDefinitionAdapter.updateOne(update, state.curveDefinitions)
        };
    }),
    on(CurveSymbolActions.fetchCurveDefinitionError, (state, { symbol, error }) => {
        const update: Update<StoreCurveDefinition> = {
            id: symbol,
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            curveDefinitions: curveDefinitionAdapter.updateOne(update, state.curveDefinitions)
        };
    })
);

export const curveSymbolFeature = createFeature({ name: curveSymbolFeatureKey, reducer: curveSymbolReducer });
