import { createReducer, on } from '@ngrx/store';
import { WebsocketStatusEnum, WebsocketStatusType } from '@shared/websocket-status';
import { uniq } from 'lodash';
import { WebSocketSymbolsActions } from './ws-symbol.actions';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { isValid } from 'date-fns';

export const webSocketSymbolsFeatureKey = 'webSocketSymbols';

export interface SymbolQuote {
    id: string;
    symbol: string;
    fields: { [key: string]: number };
    index: Date;
    lastPriceHistory?: number[];
}

export interface WebSocketSymbolsSubscription {
    symbol: string;
    status?: 'subscribed' | 'subscribing' | 'unsubscribing' | 'subscribe-error' | 'unknown';
    lastStatusChange?: Date;
}

export type WebsocketSymbolSubscriber = {
    id: string;
    symbols: string[];
};

export const subscriptionEntityAdapter = createEntityAdapter<WebSocketSymbolsSubscription>({
    selectId: subscription => subscription.symbol
});

export const symbolQuoteEntityAdapter = createEntityAdapter<SymbolQuote>({
    selectId: quote => quote.symbol
});

export const subscriberEntityAdapter = createEntityAdapter<WebsocketSymbolSubscriber>({
    selectId: subscriber => subscriber.id
});

export interface WebSocketSymbolsState {
    subscribers: EntityState<WebsocketSymbolSubscriber>;
    subscriptions: EntityState<WebSocketSymbolsSubscription>;
    symbolQuotes: EntityState<SymbolQuote>;
    historyLimit: number;

    /**
     * Websocket connection status
     */
    websocketStatus: WebsocketStatusType;
}

const initialState: WebSocketSymbolsState = {
    subscribers: subscriberEntityAdapter.getInitialState(),
    subscriptions: subscriptionEntityAdapter.getInitialState(),
    symbolQuotes: symbolQuoteEntityAdapter.getInitialState(),

    // Default to 2, can be overridden
    historyLimit: 2,
    websocketStatus: WebsocketStatusEnum.closed
};

export const webSocketSymbolsReducer = createReducer(
    initialState,

    /**
     * Subscribing
     */
    on(WebSocketSymbolsActions.subscribeToSymbol, (state, { symbol, subscriberId }) => {
        const existingSubscription = state.subscriptions.entities[symbol];
        let subscription: WebSocketSymbolsSubscription = existingSubscription;
        if (!existingSubscription) {
            subscription = {
                symbol,
                lastStatusChange: new Date(),
                status: 'subscribing'
            };
        }

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.upsertOne(subscription, state.subscriptions)
        };
    }),

    on(WebSocketSymbolsActions.subscribeToSymbols, (state, { symbols, subscriberId }) => {
        const existingSubscriptions = state.subscriptions.entities;
        const symbolState: WebSocketSymbolsSubscription[] = symbols.map(symbol => {
            if (existingSubscriptions[symbol]) {
                return existingSubscriptions[symbol];
            }
            return {
                symbol,
                status: 'subscribing',
                lastStatusChange: new Date()
            };
        });

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.upsertMany(symbolState, state.subscriptions)
        };
    }),

    /**
     * Subscription Success
     */
    on(WebSocketSymbolsActions.subscribeToSymbolSuccess, (state, { symbol, subscriberId }) => {
        const existingSubscriber = state.subscribers.entities[subscriberId];

        let subscriber: WebsocketSymbolSubscriber;
        if (existingSubscriber) {
            subscriber = {
                ...existingSubscriber,
                symbols: uniq([...existingSubscriber.symbols, symbol])
            };
        } else {
            subscriber = {
                id: subscriberId,
                symbols: [symbol]
            };
        }

        const updatedSubscription: Update<WebSocketSymbolsSubscription> = {
            id: symbol,
            changes: { status: 'subscribed', lastStatusChange: new Date() }
        };

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.updateOne(updatedSubscription, state.subscriptions),
            subscribers: subscriberEntityAdapter.upsertOne(subscriber, state.subscribers)
        };
    }),
    on(WebSocketSymbolsActions.subscribeToSymbolsSuccess, (state, { symbols, subscriberId }) => {
        const existingSubscriber = state.subscribers.entities[subscriberId];

        let subscriber: WebsocketSymbolSubscriber;
        if (existingSubscriber) {
            subscriber = {
                ...existingSubscriber,
                symbols: uniq([...existingSubscriber.symbols, ...symbols])
            };
        } else {
            subscriber = {
                id: subscriberId,
                symbols
            };
        }

        const updatedSymbolsSubscriptions: Update<WebSocketSymbolsSubscription>[] = symbols.map(symbol => ({
            id: symbol,
            changes: {
                status: 'subscribed',
                lastStatusChange: new Date()
            }
        }));

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.updateMany(updatedSymbolsSubscriptions, state.subscriptions),
            subscribers: subscriberEntityAdapter.upsertOne(subscriber, state.subscribers)
        };
    }),

    /**
     * Unsubscribing
     */
    on(WebSocketSymbolsActions.unsubscribeFromSymbolSuccess, (state, { symbol, subscriberId }) => {
        const subscriber = subscriberEntityAdapter.getSelectors().selectEntities(state.subscribers)[subscriberId];
        if (!subscriber) {
            return state;
        }

        const hasOtherSubscribers = _doesSymbolHaveOtherSubscribers(symbol, state.subscribers, subscriberId);

        let newState: WebSocketSymbolsState = {
            ...state
        };

        if (!hasOtherSubscribers) {
            newState.subscriptions = subscriptionEntityAdapter.removeOne(symbol, state.subscriptions);
        }

        const newSymbols = subscriber.symbols.filter(s => s !== symbol);
        if (newSymbols.length === 0) {
            newState.subscribers = subscriberEntityAdapter.removeOne(subscriberId, state.subscribers);
        } else {
            const subscriberUpdate: Update<WebsocketSymbolSubscriber> = {
                id: subscriberId,
                changes: {
                    symbols: newSymbols
                }
            };
            newState.subscribers = subscriberEntityAdapter.updateOne(subscriberUpdate, state.subscribers);
        }

        return {
            ...newState
        };
    }),
    on(WebSocketSymbolsActions.unsubscribeFromSymbolsSuccess, (state, { symbols, subscriberId }) => {
        const subscriber = subscriberEntityAdapter.getSelectors().selectEntities(state.subscribers)[subscriberId];
        if (!subscriber) {
            return state;
        }

        const symbolsSubscriptionsToRemove = symbols.filter(symbol => !_doesSymbolHaveOtherSubscribers(symbol, state.subscribers, subscriberId));

        let newState: WebSocketSymbolsState = {
            ...state,
            subscriptions: subscriptionEntityAdapter.removeMany(symbolsSubscriptionsToRemove, state.subscriptions)
        };

        const newSymbols = subscriber.symbols.filter(s => symbols.includes(s));
        if (newSymbols.length === 0) {
            newState.subscribers = subscriberEntityAdapter.removeOne(subscriberId, state.subscribers);
        } else {
            const subscriberUpdate: Update<WebsocketSymbolSubscriber> = {
                id: subscriberId,
                changes: {
                    symbols: newSymbols
                }
            };
            newState.subscribers = subscriberEntityAdapter.updateOne(subscriberUpdate, state.subscribers);
        }

        return {
            ...newState
        };
    }),

    /**
     * Subscription Errors
     */
    on(WebSocketSymbolsActions.subscribeToSymbolError, (state, { symbol, subscriberId, error }) => {
        const subscription: Update<WebSocketSymbolsSubscription> = {
            id: symbol,
            changes: {
                lastStatusChange: new Date(),
                status: 'subscribe-error'
            }
        };

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.updateOne(subscription, state.subscriptions)
        };
    }),
    on(WebSocketSymbolsActions.subscribeToSymbolsError, (state, { symbols, error }) => {
        const updatedSymbolsSubscriptions: Update<WebSocketSymbolsSubscription>[] = symbols.map(symbol => ({
            id: symbol,
            changes: {
                status: 'subscribe-error',
                lastStatusChange: new Date()
            }
        }));

        return {
            ...state,
            subscriptions: subscriptionEntityAdapter.updateMany(updatedSymbolsSubscriptions, state.subscriptions)
        };
    }),

    /**
     * Price updates
     */
    on(WebSocketSymbolsActions.updateRealTimeFieldsForSymbol, (state, { symbol, fields, index }) => {
        const updatedFields: { [key: string]: number } = Object.fromEntries(
            fields.map(item => {
                const [key, value] = Object.entries(item)[0]; // Get key-value pair
                return [key as string, value as number];
            })
        );

        const existingQuote = state.symbolQuotes.entities[symbol];
        const currentFields = existingQuote?.fields || {};
        const newFields = { ...currentFields, ...updatedFields };

        let lastPriceHistory = existingQuote?.lastPriceHistory || [];
        const newLastPrice = updatedFields.LAST;
        const previousLastPrice = lastPriceHistory.length > 0 ? lastPriceHistory[lastPriceHistory.length - 1] : null;

        if ('LAST' in updatedFields && newLastPrice !== previousLastPrice) {
            lastPriceHistory = [...lastPriceHistory, newLastPrice].slice(-state.historyLimit);
        }

        const updatedQuote: SymbolQuote = {
            id: symbol,
            symbol,
            fields: newFields,
            index: isValid(index) ? index : existingQuote?.index,
            lastPriceHistory
        };

        return {
            ...state,
            symbolQuotes: symbolQuoteEntityAdapter.upsertOne(updatedQuote, state.symbolQuotes)
        };
    }),

    /**
     * Websocket connection status
     */
    on(WebSocketSymbolsActions.updateWebsocketStatus, (state, { websocketStatus, retry, attempt }) => {
        return {
            ...state,
            websocketStatus
        };
    })
);

/**
 * Helpers
 */

/**
 * Check if the symbol has other subscribers besides the one being removed.
 * @param symbol
 * @param allSubscribersState
 * @param excludeSubscriber
 */
export function _doesSymbolHaveOtherSubscribers(
    symbol: string,
    allSubscribersState: EntityState<WebsocketSymbolSubscriber>,
    excludeSubscriber: string
) {
    const allSubscribers = subscriberEntityAdapter.getSelectors().selectAll(allSubscribersState);
    return allSubscribers.some(subscriber => {
        if (subscriber.id === excludeSubscriber) {
            return false;
        }
        return subscriber.symbols.includes(symbol);
    });
}
