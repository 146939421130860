import { SymbolActions } from '@app/+shared-state/symbol/symbol.actions';
import { createEntityAdapter, EntityAdapter, EntityState, Update } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { FieldLabelDto, SymbolInformation } from '@shared/api/data-management/generated';
import { fetchErrorProps, fetchStartProps, FetchStateProps, fetchSuccessProps } from '../fetch-state-props';

export const symbolFeatureKey = 'symbol';

export type SymbolFields = { symbol: string; fields: FieldLabelDto[] } & FetchStateProps;
export const symbolFieldsAdapter: EntityAdapter<SymbolFields> = createEntityAdapter({
    selectId: sf => sf.symbol
});

export type StoreSymbolInformation = { symbol: string; symbolInformation: SymbolInformation } & FetchStateProps;
export const symbolInformationAdapter: EntityAdapter<StoreSymbolInformation> = createEntityAdapter({
    selectId: si => si.symbol
});

export type SymbolState = {
    symbolFields: EntityState<SymbolFields>;
    symbolInformation: EntityState<StoreSymbolInformation>;
};

export const initialState: SymbolState = {
    symbolFields: symbolFieldsAdapter.getInitialState(),
    symbolInformation: symbolInformationAdapter.getInitialState()
};

export const symbolReducer = createReducer<SymbolState>(
    initialState,

    /**
     * Symbol fields
     */
    on(SymbolActions.fetchSymbolFields, (state, { force, symbol }) => {
        const existing = state.symbolFields.entities[symbol];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: SymbolFields = {
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.symbolFields.entities[symbol]
        };
        return {
            ...state,
            symbolFields: symbolFieldsAdapter.upsertOne(upsertValue, state.symbolFields)
        };
    }),
    on(SymbolActions.fetchSymbolFieldsSuccess, (state, { symbol, fields }) => {
        const update: Update<SymbolFields> = {
            id: symbol,
            changes: {
                ...fetchSuccessProps(),
                fields
            }
        };
        return {
            ...state,
            symbolFields: symbolFieldsAdapter.updateOne(update, state.symbolFields)
        };
    }),
    on(SymbolActions.fetchSymbolFieldsError, (state, { symbol, error }) => {
        const update: Update<SymbolFields> = {
            id: symbol,
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            symbolFields: symbolFieldsAdapter.updateOne(update, state.symbolFields)
        };
    }),

    /**
     * Symbol information
     */
    on(SymbolActions.fetchSymbolInformation, (state, { force, symbol }) => {
        const existing = state.symbolInformation.entities[symbol];
        if (!force && existing?.loaded && !existing?.loadingError) {
            return { ...state };
        }
        const upsertValue: StoreSymbolInformation = {
            symbol,
            ...fetchStartProps(existing?.loaded),
            ...state.symbolInformation.entities[symbol]
        };
        return {
            ...state,
            symbolInformation: symbolInformationAdapter.upsertOne(upsertValue, state.symbolInformation)
        };
    }),
    on(SymbolActions.fetchSymbolInformationSuccess, (state, { symbol, symbolInformation }) => {
        const update: Update<StoreSymbolInformation> = {
            id: symbol,
            changes: {
                ...fetchSuccessProps(),
                symbolInformation
            }
        };
        return {
            ...state,
            symbolInformation: symbolInformationAdapter.updateOne(update, state.symbolInformation)
        };
    }),
    on(SymbolActions.fetchSymbolInformationError, (state, { symbol, error }) => {
        const update: Update<StoreSymbolInformation> = {
            id: symbol,
            changes: {
                ...fetchErrorProps(error)
            }
        };
        return {
            ...state,
            symbolInformation: symbolInformationAdapter.updateOne(update, state.symbolInformation)
        };
    })
);

export const symbolFeature = createFeature({ name: symbolFeatureKey, reducer: symbolReducer });
