import { symbolFeature, symbolFieldsAdapter, symbolInformationAdapter } from '@app/+shared-state/symbol/symbol.reducer';
import { createSelector } from '@ngrx/store';
import { FieldLabelDto } from '@shared/api/data-management/generated';

export const { selectSymbolFields, selectSymbolInformation, selectSymbolState } = symbolFeature;

export const {
    selectIds: _selectSymbolFieldIds,
    selectEntities: _selectSymbolFieldEntities,
    selectAll: _selectAllSymbolFields,
    selectTotal: _selectTotalSymbolFields
} = symbolFieldsAdapter.getSelectors();

export const {
    selectIds: _selectSymbolInformationIds,
    selectEntities: _selectSymbolInformationEntities,
    selectAll: _selectAllSymbolInformation,
    selectTotal: _selectTotalSymbolInformation
} = symbolInformationAdapter.getSelectors();

export const selectSymbolFieldEntities = createSelector(selectSymbolFields, _selectSymbolFieldEntities);
export const selectAllSymbolFields = createSelector(selectSymbolFields, _selectAllSymbolFields);
export const selectSymbolFieldsForSymbol = (symbol: string) =>
    createSelector(selectSymbolFieldEntities, symbolFields => symbolFields[symbol] || null);

export const selectUniqueFields = createSelector(selectAllSymbolFields, allSymbolFields => {
    const fields: FieldLabelDto[] = [];
    const existingFields = new Set<string>();

    for (const symbolField of allSymbolFields) {
        if (symbolField.fields) {
            for (const item of symbolField.fields) {
                if (!existingFields.has(item.field)) {
                    fields.push(item);
                    existingFields.add(item.field);
                }
            }
        }

        return fields;
    }
});

export const selectSymbolInformationEntities = createSelector(selectSymbolInformation, _selectSymbolInformationEntities);
export const selectAllSymbolInformation = createSelector(selectSymbolInformation, _selectAllSymbolInformation);
export const selectSymbolInformationForSymbol = (symbol: string) =>
    createSelector(selectSymbolInformationEntities, symbolInformation => symbolInformation[symbol] || null);
