import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
    subscriptionEntityAdapter,
    webSocketSymbolsFeatureKey,
    WebSocketSymbolsState,
    symbolQuoteEntityAdapter,
    subscriberEntityAdapter
} from '@app/+shared-state/real-time-data/ws-symbol.reducer';

export const selectWebSocketState = createFeatureSelector<WebSocketSymbolsState>(webSocketSymbolsFeatureKey);

export const selectWebSocketSubscriptions = createSelector(selectWebSocketState, state => state.subscriptions);
export const selectWebSocketSubscribers = createSelector(selectWebSocketState, state => state.subscribers);

/**
 * Subscribers
 */
export const { selectAll: selectAllSubscribers, selectEntities: selectAllSubscribersEntities } = subscriberEntityAdapter.getSelectors();
export const selectSubscriber = (subscriberId: string) => createSelector(selectWebSocketState, state => state.subscribers)[subscriberId];
export const doesSubscriberExist = (subscriberId: string) =>
    createSelector(selectWebSocketState, state => {
        return !!selectAllSubscribersEntities(state.subscribers)[subscriberId];
    });

export const hasSubscribers = (symbol: string, subscriberId: string) =>
    createSelector(selectWebSocketState, state => selectAllSubscribers(state.subscribers).some(subscriber => subscriber.symbols.includes(symbol)));

export const doesSymbolHaveOtherSubscribers = (symbol: string, excludeSubscriber: string) =>
    createSelector(selectWebSocketState, state =>
        selectAllSubscribers(state.subscribers).some(subscriber => {
            if (subscriber.id === excludeSubscriber) {
                return false;
            }
            return subscriber.symbols.includes(symbol);
        })
    );

// Selectors for subscriptions
export const { selectAll: selectAllSubscriptions, selectEntities: selectSubscriptionEntities } = subscriptionEntityAdapter.getSelectors();
export const selectSubscriptions = createSelector(selectWebSocketState, state => selectSubscriptionEntities(state.subscriptions));

export const isSubscribedToSymbol = (symbol: string) =>
    createSelector(selectWebSocketState, state => selectSubscriptionEntities(state.subscriptions)[symbol]?.status === 'subscribed');

export const selectSubscription = (symbol: string) =>
    createSelector(selectWebSocketState, state => selectSubscriptionEntities(state.subscriptions)[symbol]);

// Selectors for prices
const { selectEntities: selectQuoteSymbolEntities } = symbolQuoteEntityAdapter.getSelectors();
export const selectAllQuoteSymbols = createSelector(selectWebSocketState, state => selectQuoteSymbolEntities(state.symbolQuotes));

export const selectLastPriceHistoryLimit = createSelector(selectWebSocketState, state => state.historyLimit);

// Selector for a specific symbol and its last real time value.
export const selectRealTimeFieldForSymbol = (symbol: string, field: string) =>
    createSelector(selectAllQuoteSymbols, symbolQuotes => symbolQuotes[symbol]?.fields?.[field] || null);

export const selectLastPriceHistoryForSymbol = (symbol: string) =>
    createSelector(
        createSelector(selectAllQuoteSymbols, symbolQuotes => symbolQuotes[symbol]?.lastPriceHistory || []),
        selectLastPriceHistoryLimit,
        (lastPriceHistory: number[], historyLimit: number) => lastPriceHistory.slice(-historyLimit)
    );

export const selectLastPriceEntryForSymbol = (symbol: string, field: string) =>
    createSelector(selectAllQuoteSymbols, symbolQuotes => {
        return {
            price: symbolQuotes[symbol]?.fields?.[field] || null,
            index: symbolQuotes[symbol]?.index
        };
    });

export const selectAllFieldsForSymbolAsMap = (symbol: string) =>
    createSelector(selectAllQuoteSymbols, symbolQuotes => {
        const { fields } = symbolQuotes[symbol];
        return new Map(Object.entries(fields).map(([key, value]) => [key, value as number]));
    });

export const selectAllFieldsForSymbol = (symbol: string) =>
    createSelector(selectAllQuoteSymbols, symbolQuotes => {
        return symbolQuotes[symbol]?.fields;
    });

// Selector to get prices for multiple state
export const selectMultipleSymbolPrices = (symbols: { symbol: string; field: string }[]) =>
    createSelector(selectAllQuoteSymbols, prices => {
        const result: { [key: string]: number | undefined } = {};
        symbols.forEach(({ symbol, field }) => {
            const key = `${symbol}-${field}`;
            result[key] = 5;
        });
        return result;
    });

// Selector to get prices for multiple state as an array
export const selectMultipleSymbolPricesArray = (symbols: { symbol: string; field: string }[]) =>
    createSelector(selectAllQuoteSymbols, prices => {
        return symbols.map(({ symbol, field }) => {
            const key = `${symbol}-${field}`;
            // return prices[key]?.price;
            return 5;
        });
    });

/**
 * Websocket connection status selectors
 */
export const selectRealTimeSymbolDataWebSocketStatus = createSelector(selectWebSocketState, state => state.websocketStatus);
