import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { WebsocketStatusType } from '@shared/websocket-status';

export const WebSocketSymbolsActions = createActionGroup({
    source: 'WebSocketSymbols',
    events: {
        'Init WebSocket Connection': emptyProps(),
        'Connection Established': emptyProps(),
        'Connection Failed': props<{ error: string }>(),
        'Subscribe to Symbol': props<{ subscriberId: string; symbol: string }>(),
        'Subscribe to Symbol Success': props<{ subscriberId: string; symbol: string }>(),
        'Subscribe to Symbol Error': props<{ subscriberId: string; symbol: string; error: string }>(),
        'Subscribe to Symbols': props<{ subscriberId: string; symbols: string[] }>(),
        'Subscribe to Symbols Success': props<{ subscriberId: string; symbols: string[] }>(),
        'Subscribe to Symbols Error': props<{ subscriberId: string; symbols: string[]; error: string }>(),

        'Unsubscribe from Symbol': props<{ subscriberId: string; symbol: string }>(),
        'Unsubscribe from Symbol Success': props<{ subscriberId: string; symbol: string }>(),
        'Unsubscribe from Symbol Error': props<{ error: string }>(),
        'Unsubscribe from Symbols': props<{ subscriberId: string; symbols: string[] }>(),
        'Unsubscribe from Symbols Success': props<{ subscriberId: string; symbols: string[] }>(),
        'Unsubscribe from Symbols Error': emptyProps(),
        'Unsubscribe from All Symbols': emptyProps(),

        'Update Real Time Fields For Symbol': props<{ symbol: string; fields: { [key: string]: number }[]; index: Date }>(),

        'Update Websocket Status': props<{ websocketStatus: WebsocketStatusType; retry?: boolean; attempt?: number }>()
    }
});
